export const InsideImagesList = [
    require('../assets/images/hotelImages/InsideImages/InsideImage1.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage2.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage3.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage4.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage5.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage6.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage7.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage8.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage9.jpg'),
    require('../assets/images/hotelImages/InsideImages/InsideImage10.jpg')
]

export const OutsideImagesList = [
    require('../assets/images/hotelImages/OutsideImages/OutsideImage1.jpg'),
    require('../assets/images/hotelImages/OutsideImages/OutsideImage2.jpg'),
    require('../assets/images/hotelImages/OutsideImages/OutsideImage3.jpg'),
    require('../assets/images/hotelImages/OutsideImages/OutsideImage4.jpg'),
    require('../assets/images/hotelImages/OutsideImages/OutsideImage5.jpg'),
    require('../assets/images/hotelImages/OutsideImages/OutsideImage6.jpg'),
    require('../assets/images/hotelImages/OutsideImages/OutsideImage7.jpg'),
    require('../assets/images/hotelImages/OutsideImages/OutsideImage8.jpg')
]

export const BalconyViewImagesList = [
    require('../assets/images/hotelImages/BalconyView/BalconyView1.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView2.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView3.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView4.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView5.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView6.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView7.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView8.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView9.jpg'),
    require('../assets/images/hotelImages/BalconyView/BalconyView10.jpg')
]

export const roomImages = {
    rohtangView: require('../assets/images/hotelImages/roomImages/rohtang.webp'),
    sohtangView: require('../assets/images/hotelImages/roomImages/solang.webp'),
    valleyView: require('../assets/images/hotelImages/roomImages/valley.webp'),
    duplexView: require('../assets/images/hotelImages/roomImages/duplex.webp'),
}

export const images = {
    parkingIcon: require("../assets/images/icons/parkingIcon.png"),
    restrauntIcon: require("../assets/images/icons/restrauntIcon.png"),
    wifiicon: require("../assets/images/icons/wifiIcon.png"),
    panaromicViewIcon: require('../assets/images/icons/panaromicView.png'),
    bonfireIcon: require('../assets/images/icons/bonfire.png'),
    breakfastIcon: require('../assets/images/icons/breakfast.png'),
    blackCircleIcon: require('../assets/images/icons/black-circle.png'),
    agodaLogo: require('../assets/images/icons/agodaLogo.png'),
    mmtIcon: require('../assets/images/icons/makeMyTripIcon.png'),
};
export const headerLogo = require('../assets/images/logo/2.png');
export const influencersImages = {
    kritiMehra: require("../assets/images/influencers/kriti_mehra.webp"),
    ajju: require("../assets/images/influencers/ajju.webp"),
    ashna: require("../assets/images/influencers/ashna_chand.webp"),
};

export const logos = {
    agoda: require('../assets/images/logo/agodaLogo.png'),
    booking: require('../assets/images/logo/booking.png'),
    easeMyTrip: require('../assets/images/logo/easeMyTrip.png'),
    goIbibo: require('../assets/images/logo/goIbiboLogo.png'),
    wego: require('../assets/images/logo/wego.png'),
    makeMyTrip: require("../assets/images/logo/makeMyTripIcon.png")
}