import { roomImages } from "./ImagesList"

export const SpecialRoom = {
    title: 'Duplex',
    image: roomImages.duplexView,
    description: 'Nestled within our Duplex Rooms at Matsya Manali is an enchanting expanse designed to accommodate up to four discerning guests with seamless grace. This beguiling haven not only exudes comfort and charm but also generously offers ample space, embracing each visitor with a cocoon of extravagance. From couples seeking a romantic escapade to families yearning for unforgettable bonding moments, our Duplex Rooms extend an invitation to create cherished memories amidst the breathtaking beauty of Manali.'
}

export const RoomsSeperated = {
    valleyView: {
        title: 'Valley View',
        image: roomImages.valleyView,
        description: 'Our Valley View rooms are designed to provide you with a seamless blend of comfort and aesthetics. With large windows and a private balcony, you can soak in the panoramic views of the picturesque valley and the charming town of Manali. Witness the enchanting sight of apple orchards sprawling in the foreground, as the majestic hills of Manali adorn the horizon.'

    },
    solangView: {
        title: 'Solang View',
        image: roomImages.sohtangView,
        description: 'One of the highlights of our Solang View rooms is the large windows and balconies that showcase the beauty of Solang Valley. Imagine waking up to the soft hues of dawn, with the first rays of sunlight gently illuminating the valley and casting a warm glow on the apple orchards. Throughout the day, you can sit back and marvel at the ever-changing scenery as the mountains are bathed in different shades of light, creating a mesmerizing spectacle.'

    },
    rohtangView: {
        title: 'Rohtang View',
        image: roomImages.rohtangView,
        description: "Step into the Rohtang View Room and be enchanted by its thoughtfully designed ambiance, offering unparalleled enjoyment of the breathtaking landscapes. The room's expansive windows provide awe-inspiring frames to the majestic Rohtang Pass and its mesmerizing mountains, stretching as far as the eye can see. Relax in the strategically placed comfortable seating area by the window, allowing the tranquil scenery to transport you to a world of serenity. The snow-capped peaks, lush valleys, and rugged terrain all come together to create nature's masterpiece, leaving you in awe of its beauty and wonder."
    }
}